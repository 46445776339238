<template>
    <div class="w-100 text-left">
        <b-modal
            v-model="modal"
            size="xl"
            title="Configurações do banner"
            hide-footer
        >
            <b-row style="padding: 1rem;display: flex;">
                <b-input-group size="sm">
                    <b-form-checkbox id="StaDebBordero"
                                    title="Se estiver ativo não será possível criar regras para bases específicas"
                                    v-model="showBannerForAll"
                                    :disabled="bannerExclusiveList.length > 0">
                    <span>Exibir para todas as bases</span>
                    </b-form-checkbox>
                </b-input-group>
            </b-row>
            <b-form-input
                v-model="search"
                type="search"
                placeholder="Buscar base"
                debounce="600"
                class="mb-4"
            />
            <b-table
                striped="striped"
                outlined="outlined"
                class="fontSize tableClicked bg-white"
                small="small"
                hover="hover"
                responsive
                show-empty
                empty-text="Sem items"
                style="height:500px"
                :total-rows="filterList.length"
                :fields="fields"
                :items="filterList"
                :busy="loading"
            >
    
            <template slot="actions" slot-scope="data">
                <b-button
                    size="sm"
                    :title="(showBannerForAll)? 'Desmarque a opção de exibir para todas as bases' : 'Adicionar regra de exibição do parceiro'"
                    variant="outline-primary"
                    :disabled="(showBannerForAll)"
                    v-if="!data.item.hasRole"
                    @click="changeCompanieRole('add', data.item)"
                >
                    Adicionar na lista
                </b-button>
                <b-button
                    v-else
                    size="sm"
                    title="Remover regra de exibição do parceiro"
                    variant="danger"
                    @click="changeCompanieRole('delete', data.item)"
                >
                   Remover da lista
                </b-button>
            </template>
            
            </b-table>
        </b-modal>
    
    </div>
    </template>
    
    <script>
    import { func } from "@/functions";
    import { partnerService } from '@/components/common/services/partner';
    export default {
        props: {
            eventId: {
                type: [Number, String],
                required: true
            },
            eventData: {
                type: Object,
                required: true
            }
        },
        mixins: [func],
        data(){
            return {
                modal: true,
                showBannerForAll: this.eventData.showBannerForAll,
                search: '',
                bannerExclusiveList: [],
                companiesList: [],
                loading: false,
                fields: {
                    name: {
                        label: 'Nome da base'
                    },
                    actions: {
                        label: ''
                    }
                },
            }
        },
        watch: {
            modal(event) {
                this.$emit('close', event)
            },
            showBannerForAll(event) {
                this.changeRoleForAll()
            }
        },
        computed: {
            filterList(){
                if (this.search === '') {
                    return this.companiesList;
                }
                return this.companiesList.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
            }
        },
        async mounted () {
            await this.mountCompaniesList()
        },
        methods: {
            async changeRoleForAll () {
                await partnerService.updateBannerRole({
                    loggedId: this.getLoggedId(),
                    id_evento: this.eventId,
                    show_banner_for_all: this.showBannerForAll
                })
                await this.mountCompaniesList()
            },
            async changeCompanieRole (type, item) {
                this.loading = true;
                const dataToSave = {
                    loggedId: this.getLoggedId(),
                    id_evento: this.eventId,
                    base: this.eventData.id_base,
                    partner: item.id,
                    show_banner_for_all: this.showBannerForAll
                }
                if (type === 'add') {
                    dataToSave.is_to_add = true
                    this.showBannerForAll = false
                    await partnerService.updateBannerRole(dataToSave)
                }
                if (type === 'delete') {
                    dataToSave.is_to_add = false
                    await partnerService.updateBannerRole(dataToSave)
                }
                await this.mountCompaniesList()
                this.loading = false;
            },
            async mountCompaniesList(){
                this.loading = true;
                const [ companiesList, bannerExclusiveList ] = await Promise.all([
                    partnerService.withpermission(this.getLoggedId()),
                    partnerService.listBannerExclusive({ id_evento: this.eventId, loggedId: this.getLoggedId()})
                ])
                companiesList.forEach(companie => {
                    if (bannerExclusiveList) {
                        companie.hasRole = bannerExclusiveList.some(role => companie.id === role.id_partner)
                    }
                });
                this.bannerExclusiveList = bannerExclusiveList;
                
                this.companiesList = companiesList;
                this.loading = false;
            }
        }
    }
    </script>